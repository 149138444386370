import GtrSuper from "@/modules/common/components/mixins/gtr-super.mixin";
import Notification from "@/modules/common/services/notification.service";
import Container from "typedi";
import { Component } from "vue-property-decorator";

@Component({
    name: 'GtrRegistrationCancelView'
})
export default class GtrRegistrationCancelView extends GtrSuper {

    data() {
        return {}
    }

    async cancelReg () {
        if (this.$route.params.participant_cancel_auth_token) {
            try {
                const data = {
                    event_identifier: this.$route.params.event_identifier,
                    token: this.$route.params.participant_cancel_auth_token
                }
                await this.$store.dispatch('register/cancelRegistration', data, { root: true })
                Container.get(Notification).success('Registration successfully cancelled.')
            } catch (e) {
                Container.get(Notification).error('There was a problem cancelling your registration. Please try again.')
            } finally {
                await this.$router.replace({ path: `/${this.$route.params.event_identifier}`})
            }
        }
    }
}
